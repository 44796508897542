<template>
	<div class="support">
		<h1 class="theme_text center">用愛接力，美麗台灣</h1>
		<p>「金枝走演‧美麗台灣」，是一趟沒有停歇過腳步的旅程，一路走來，謝謝每位觀眾朋友，無論風雨陪著我們向前走，陪著我們一起發瘋發傻，戲棚下，歡樂與共～ 藝術旅程未完待續，我們要繼續走下去，邀請您與我們相約每個戲棚，有錢出錢，有力出力，用愛接力，美麗台灣！</p>
		<br>
		<article>
			<h2 class="theme_text">支持「金枝走演」的方式</h2>
			<hr>
			<h3>指定鄉鎮捐款：</h3>
			<p>贊助單場基本演出費全額30萬元（含演出工作費、器材費、旅運費、宣傳費、行政費等支出）。
				<ol>
					<li>您可指定金枝走演的演出鄉鎮。</li>
					<li>偏鄉、外島地區需增加之旅運支出將由劇團另募，或您也可選擇一次性捐助全額。</li>
					<li>劇團將為您開立可供年度申報抵稅的贊助收據。</li>
				</ol>
			</p>
			<h3>不定額捐款：</h3>
			<p>不限定捐款金額。您只要付出一點點金額，就能積少成多，讓金枝走演能造訪更多鄉鎮。
				<ol>
					<li>我們將於累積至30萬元之後，依金枝走演年度規劃擇定鄉鎮地點及前往演出，並公告於「最新消息」，同時另由專人告知您相關演出及贊助內容。</li>
					<li>劇團將為您開立可供年度申報抵稅的贊助收據。</li>
				</ol>
			</p>
			<h3>購買金枝花布Ｔ恤：</h3>
			<p>每件售出所得將提撥50元作為走演基金，我們將於每年底公告全年度提撥所得總額。</p>
			<br>
			<div class="center">
				<router-link to="/product">
					<el-button type="theme" round>我要購買</el-button>
				</router-link>
			</div>
			<br>
		</article>
		<article>
			<h2 class="theme_text">捐款方式</h2>
			<hr>
			<p>請填寫線上捐款單，或下載 金枝走演捐款表格 填寫後，傳真 <u>02-26186842</u> 或 email：<a href="mailto:gbt1993@gmail.com" type="primary"><u>gbt1993@gmail.com</u></a>，將由專人與您聯繫。</p>
			<ol>
				<li>郵政劃撥:
					<p>帳號：19698402，戶名：金枝演社劇團
						<br>（請於備註欄位填寫「贊助金枝走演」）</p>
				</li>
				<li>銀行匯款／ATM轉帳:
					<p>合作金庫銀行(006) 大稻埕分行，帳號：0050 717 134653
						<br>（請於匯款後來電 <a href="tel:+886-2-6637-7987" type="primary"><u>02-6637 7987</u></a> 或 <a href="mailto:gbt1993@gmail.com" type="primary"><u>email</u></a> 告知匯款帳號末５碼）</p>
				</li>
				<li>支票:
					<p>支票抬頭請開列：金枝演社劇團
						<br>郵寄地址：24945新北市八里區龍米路二段117巷39之1號 金枝演社收
						<br>（信封請註明「贊助金枝走演」）</p>
				</li>
			</ol>
		</article>
		<br>
		<article>
			<h2 class="theme_text">線上捐款單</h2>
			<hr>
			<p class="theme_text"><b>*&nbsp;必填</b></p>
			<el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position="left" :label-width="$root.rwd < 3 ? '110px' : ''">
				<el-form-item prop="email" label="Email">
					<el-input v-model="ruleForm.email" placeholder="電子信箱" class="round" />
				</el-form-item>
				<el-form-item prop="name" label="捐款人姓名">
					<el-input v-model="ruleForm.name" class="round" />
				</el-form-item>
				<el-form-item prop="identity" label="身分證／統編">
					<el-input v-model="ruleForm.identity" class="round" />
				</el-form-item>
				<el-form-item prop="birthday" label="出生年">
					<el-date-picker v-model="ruleForm.birthday" value-format="timestamp" type="year" placeholder="選擇年份" class="round" />
				</el-form-item>
				<el-form-item prop="cellphone" label="手機號碼">
					<el-input v-model="ruleForm.cellphone" class="round" />
				</el-form-item>
				<el-form-item label="通訊地址" required>
					<div class="row-grid" gutter-x="10">
						<el-form-item prop="zipcode">
							<el-input v-model="ruleForm.zipcode" placeholder="郵遞區號" style="width:auto;" class="round" />
						</el-form-item>
						<el-form-item prop="city">
							<el-select v-model="ruleForm.city" placeholder="縣市" class="round" @change="$set(ruleForm, 'district', '')">
								<template v-for="(val, key, i) in administrativeDistrict">
									<el-option :value="key" :key="i" />
								</template>
							</el-select>
						</el-form-item>
						<el-form-item prop="district">
							<el-select v-model="ruleForm.district" placeholder="鄉鎮市區" class="round">
								<template v-for="(district, i) in (administrativeDistrict[ruleForm.city] || new Array)">
									<el-option :value="district" :key="i" />
								</template>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item prop="address">
						<el-input v-model="ruleForm.address" placeholder="里(村)/路(街)/號/樓(室)" class="round" />
					</el-form-item>
				</el-form-item>
				<el-form-item prop="how_much" label="捐款金額" ref="how_much">
					<el-input v-model.number="ruleForm.how_much" type="number" class="round" />
					<br>
					<span class="theme_text">{{(ruleForm.how_much || 0) | currencyFilter}}</span>
				</el-form-item>
				<el-form-item label="指定鄉鎮">
					<div class="row-grid" gutter-x="10" gutter-y="10">
						<el-select v-model="ruleForm.specified.city" placeholder="縣市" class="round" @change="$set(ruleForm.specified, 'district', '')">
							<el-option value="" label="" />
							<template v-for="(val, key, i) in administrativeDistrict">
								<el-option :value="key" :key="i" />
							</template>
						</el-select>
						<el-select v-model="ruleForm.specified.district" placeholder="鄉鎮市區" class="round">
							<template v-for="(district, i) in (administrativeDistrict[ruleForm.specified.city] || new Array)">
								<el-option :value="district" :key="i" />
							</template>
						</el-select>
					</div>
					<b class="theme_text">（單場全額贊助者填寫）</b>
				</el-form-item>
				<el-form-item label="捐款收據">
					<div class="row-grid" align-y="center" gutter-x="10" gutter-y="10">
						<el-form-item>
							<el-input v-model="ruleForm.receipt.title" placeholder="抬頭" class="round" style="width:unset;" />
						</el-form-item>
						<el-form-item>
							<el-input v-model="ruleForm.receipt.uniform" placeholder="統編" class="round" style="width:unset;" />
						</el-form-item>
					</div>
					<b class="theme_text">（捐款收據可作為您年度報繳所得稅，列舉扣除之憑證）</b>
				</el-form-item>
				<el-form-item prop="payment" label="捐款方式">
					<div class="col" gutter="10">
						<el-radio v-model="ruleForm.payment" label="銀行匯款/ATM轉帳" />
						<el-radio v-model="ruleForm.payment" label="郵政劃撥" />
						<el-radio v-model="ruleForm.payment" label="支票郵寄" />
					</div>
				</el-form-item>
			</el-form>
			<br>
			<div class="center">
				<el-button type="theme" round @click="handleSubmit()">立即捐款</el-button>
			</div>
		</article>
		<br>
		<article style="text-align:center;">
			<h2 class="theme_text">有力出力！</h2>
			<p>邀請您走出家門口，戶外戲棚下，拉張板凳，不分彼此，同歡共樂，用藝術看見台灣的美好。
				<br>如有任何問題，歡迎來電洽詢 (02)-6637-7987。</p>
			<br>
			<h2 class="theme_text">金枝走演．美麗台灣 感恩有您同行！</h2>
		</article>
	</div>
</template>

<script>
import { rule } from "@/utils/shared-methods";
import administrativeDistrict from "@/assets/data/administrative-district.json";
import { mapState, mapActions } from "vuex";
const ruleForm = function () {
	return {
		// how_much: 0,
		payment: "銀行匯款/ATM轉帳",
		receipt: {
			title: "",
			uniform: ""
		},
		specified: {
			city: "",
			district: ""
		}
	}
}
export default {
	data() {
		return {
			administrativeDistrict,
			ruleForm: new ruleForm,
			rules: {
				email: [
					{ required: true, message: "信箱" },
					{ type: "email", message: "格式" },
				],
				name: rule(),
				cellphone: rule(/^[0-9]{10}$/, "請填寫正確手機（10位數字）"),
				how_much: [
					{ required: true, message: "捐款新額（數字）" },
					{ type: "number", message: "只能填數字" },
					{
						validator: (rule, value, callback) => {
							if (Number(value)) callback();
							else callback(new Error("金額不能為０"))
						}
					},
				],
				"receipt.title": rule(),
				"receipt.uniform": rule(/^[0-9]{8}$/, "統編（８位數字）"),
				payment: rule(),
			},
		}
	},
	computed: {
		...mapState("auth", ["_auth"]),
	},
	methods: {
		...mapActions("support", ["_createData"]),
		handleSubmit() {
			this.$refs["ruleForm"].validate(async valid => {
				if (valid) {
					this.$message.success(await this._createData(this.ruleForm));
					this.$refs["ruleForm"].resetFields();
					this._.assign(this.ruleForm, new ruleForm);
				}
			})
		}
	},
	created() {
		if (this._auth) this.$set(this.$data, "ruleForm", this._.assign(new ruleForm, this._auth));
	}
}
</script>